import React from 'react';
import { Image as SImage, Grid } from 'semantic-ui-react';
import DefaultImageSVG from '@plone/volto/components/manage/Blocks/Listing/default-image.svg';
import ConditionalLink from '@plone/volto/components/manage/ConditionalLink/ConditionalLink';
import Image from '@plone/volto/components/theme/Image/Image';
import { useSelector } from 'react-redux';
import moment from 'moment';

const PodcastCard = ({ item, isEditMode, destakatua }) => {
  let lang = useSelector((state) => state.intl.locale);
  moment.locale(lang);
  return (
    <Grid className="podcast-card">
      {!destakatua ? (
        <Grid.Column width={12}>
          <Grid.Row>
            <ConditionalLink item={item} condition={!isEditMode}>
              {!item.image_field && (
                <SImage
                  circular
                  src={DefaultImageSVG}
                  alt="This content has no image, this is a default placeholder."
                  size="small"
                />
              )}
              {item?.image_field && (
                <Image className="ui image" item={item} alt={item.title} />
              )}
            </ConditionalLink>
          </Grid.Row>
          <Grid.Row>
            {item?.effective !== null && (
              <p className="newsitem-date">
                {moment(item?.effective).format('L')}
              </p>
            )}
            <h3>
              <ConditionalLink item={item} condition={!isEditMode}>
                {item.title}
              </ConditionalLink>
            </h3>
          </Grid.Row>
        </Grid.Column>
      ) : (
        <>
          <Grid.Column width={6}>
            <Grid.Row>
              <ConditionalLink item={item} condition={!isEditMode}>
                {!item.image_field && (
                  <Image
                    src={DefaultImageSVG}
                    alt="This content has no image, this is a default placeholder."
                    size="small"
                  />
                )}
                {item?.image_field && (
                  <Image className="ui image" item={item} alt={item.title} />
                )}
              </ConditionalLink>
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width={6}>
            <Grid.Row>
              {item?.effective !== null && (
                <p className="newsitem-date">
                  {moment(item?.effective).format('L')}
                </p>
              )}
              <h3>
                <ConditionalLink item={item} condition={!isEditMode}>
                  {item.title}
                </ConditionalLink>
              </h3>
            </Grid.Row>
          </Grid.Column>
        </>
      )}
    </Grid>
  );
};

export default PodcastCard;
