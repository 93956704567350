import React from 'react';
import { Grid, Image as SImage, Icon } from 'semantic-ui-react';
import ConditionalLink from '@plone/volto/components/manage/ConditionalLink/ConditionalLink';
import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';
import DefaultImageSVG from '@plone/volto/components/manage/Blocks/Listing/default-image.svg';
import Image from '@plone/volto/components/theme/Image/Image';

const BookCard = ({ item, isEditMode }) => {
  return (
    <Grid className="publication-card">
      <Grid.Row>
        <Grid.Column>
          <ConditionalLink item={item} condition={!isEditMode}>
            {!item?.image_field && (
              <SImage
                centered
                src={DefaultImageSVG}
                alt="This content has no image, this is a default placeholder."
                size="small"
              />
            )}
            {item?.image_field && (
              <Image className="ui image" item={item} alt={item.title} />
            )}
          </ConditionalLink>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <h3>
            <ConditionalLink item={item} condition={!isEditMode}>
              {item.title}
            </ConditionalLink>
          </h3>
        </Grid.Column>
      </Grid.Row>
      {item?.book_prices && Object.keys(item?.book_prices).length > 0 && (
        <Grid.Row>
          <Grid.Column>
            {item?.book_prices?.paper !== undefined &&
              item?.book_prices?.paper !== 0 && (
                <p>{`Paperekoa ${item.book_prices?.paper} €`}</p>
              )}
            {item?.book_prices?.digital !== undefined && (
              <p>{`Digitala ${item.book_prices?.digital} €`}</p>
            )}
            <UniversalLink className="ui button ueu line" item={item}>
              <Icon name="shopping cart" /> ESKURATU
            </UniversalLink>
          </Grid.Column>
        </Grid.Row>
      )}
    </Grid>
  );
};

export default BookCard;
