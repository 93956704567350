import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Segment, Message } from 'semantic-ui-react';
import Image from '@plone/volto/components/theme/Image/Image';

import imageBlockSVG from '@plone/volto/components/manage/Blocks/Image/block-image.svg';
import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';

import cx from 'classnames';

const messages = defineMessages({
  PleaseChooseContent: {
    id: 'Please choose an existing content as source for this element',
    defaultMessage:
      'Please choose an existing content as source for this element',
  },
});

const BannerView = (props) => {
  const intl = useIntl();
  const { data, isEditMode } = props;
  const { buttonWhere = 'behean', buttonHarpidetza = false } = data;
  const href = data.href?.[0];
  const image = data.preview_image?.[0];
  return (
    <Segment basic className={cx(props.className, 'banner-container')}>
      {!href && isEditMode && (
        <Message>
          <div className="grid-teaser-item placeholder">
            <img src={imageBlockSVG} alt="" />
            <p>{intl.formatMessage(messages.PleaseChooseContent)}</p>
          </div>
        </Message>
      )}
      {data.buttonWhere === 'goian' && href ? (
        <UniversalLink
          className={cx(
            'ui',
            'button',
            'fluid',
            'large',
            'ueu',
            'banner-up-button',
            buttonHarpidetza && 'button-harpidetza',
          )}
          item={href}
        >
          {data.title}
        </UniversalLink>
      ) : (
        buttonWhere === 'goian' && (
          <span
            className={cx(
              'ui',
              'button',
              'fluid',
              'large',
              'ueu',
              'banner-up-button',
              buttonHarpidetza && 'button-harpidetza',
            )}
          >
            {data.title}
          </span>
        )
      )}
      {image && (
        <Image className="ui image" item={image} alt={data?.image_alt} />
      )}
      {buttonWhere === 'behean' && href ? (
        <UniversalLink
          className={cx(
            'ui',
            'button',
            'fluid',
            'large',
            'ueu',
            'banner-down-button',
            buttonHarpidetza && 'button-harpidetza',
          )}
          item={href}
        >
          {data.title}
        </UniversalLink>
      ) : (
        buttonWhere === 'behean' && (
          <span
            className={cx(
              'ui',
              'button',
              'fluid',
              'large',
              'ueu',
              'banner-down-button',
              buttonHarpidetza && 'button-harpidetza',
            )}
          >
            {data.title}
          </span>
        )
      )}
    </Segment>
  );
};

export default BannerView;
