/**
 * IkergazteEventView view component.
 * @module components/theme/View/IkergazteEventView
 */
import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Label } from 'semantic-ui-react';

import { sanitizedHTML } from 'volto-ueu/components/utils';

import StringToHTML from 'volto-ueu/components/Utils/StringToHTML';
import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';
import moment from 'moment';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Image from '@plone/volto/components/theme/Image/Image';

/**
 * IkergazteEventView view component class.
 * @function IkergazteEventView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const IkergazteEventView = ({ content, location }) => {
  let lang = useSelector((state) => state.intl.locale);
  moment.locale(lang);
  return (
    <div
      className={cx(
        'view-wrapper subsite-event',
        content.posters.length > 0 ? 'posters-container' : '',
      )}
    >
      {content.title && <h1 className="subsite-title">{content.title}</h1>}
      <Grid stackable>
        <Grid.Column width={12}>
          <div className="subsite-event-info">
            {content.start && content.end && (
              <span className="subsite-event-dates">
                Data eta ordua:{' '}
                <Label className="datetime-label">{`${moment(
                  content.start,
                ).format('MMMM')}k ${moment(content.start).format(
                  'DD',
                )} ,  ${moment(content.start).format('HH:mm')} - ${moment(
                  content.end,
                ).format('HH:mm')}`}</Label>
              </span>
            )}
            {content.description && (
              <span className="subsite-event-type">
                Mota: {content.description}
              </span>
            )}
          </div>
        </Grid.Column>
        {(content.attendees.length || sanitizedHTML(content.text?.data)) && (
          <Grid.Column width={content.preview_image ? 6 : 12}>
            {content.attendees.length > 0 && (
              <div className="event-attendee">
                {content.attendees
                  .map((att, key) => <span key={key}>{att}</span>)
                  .reduce((prev, curr) => [prev, ', ', curr])}
              </div>
            )}
            <div className="event-text">
              {sanitizedHTML(content.text?.data) && (
                <StringToHTML string={content.text.data} />
              )}
            </div>
          </Grid.Column>
        )}
        {content.preview_image && (
          <Grid.Column width={6}>
            {content.preview_image && (
              <figure>
                <Image
                  className="ui image"
                  imageField="preview_image"
                  item={content}
                  alt={content.title}
                />
                {content.image_caption && (
                  <figcaption>{content.image_caption}</figcaption>
                )}
              </figure>
            )}
          </Grid.Column>
        )}
        {content.posters.length > 0 && (
          <Grid.Column width={12}>
            <ul className="event-posters">
              {content.posters.map((p, key) => (
                <li key={key}>
                  <UniversalLink item={p}>{p?.title}</UniversalLink>
                </li>
              ))}
            </ul>
          </Grid.Column>
        )}
      </Grid>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
IkergazteEventView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    text: PropTypes.shape({
      data: PropTypes.string,
    }),
  }).isRequired,
};

export default IkergazteEventView;
