import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Button, Segment } from 'semantic-ui-react';
import { Message } from 'semantic-ui-react';

import imageBlockSVG from '@plone/volto/components/manage/Blocks/Image/block-image.svg';
import ConditionalLink from '@plone/volto/components/manage/ConditionalLink/ConditionalLink';
import Image from '@plone/volto/components/theme/Image/Image';
import cx from 'classnames';

const messages = defineMessages({
  PleaseChooseContent: {
    id: 'Please choose an existing content as source for this element',
    defaultMessage:
      'Please choose an existing content as source for this element',
  },
});

const AldizkariView = (props) => {
  const intl = useIntl();
  const { data, isEditMode } = props;
  const href = data.href?.[0];
  const hrefId = href && href.hasOwnProperty('@id') ? href['@id'] : '';
  const buttonLink = data.buttonLink?.[0];
  const image = data.preview_image?.[0];
  return (
    <Segment
      basic
      className={cx(props.className, 'banner-container aldizkari-banner')}
    >
      {!href && isEditMode && (
        <Message>
          <div className="grid-teaser-item placeholder">
            <img src={imageBlockSVG} alt="" />
            <p>{intl.formatMessage(messages.PleaseChooseContent)}</p>
          </div>
        </Message>
      )}
      {href && (href.hasPreviewImage || href.image_field || image) ? (
        <ConditionalLink
          condition={hrefId && !isEditMode && image}
          href={hrefId}
        >
          <Image
            className="ui image banner-image"
            item={image}
            alt={data?.image_alt}
          />
        </ConditionalLink>
      ) : (
        ''
      )}
      {data?.title && (
        <ConditionalLink
          condition={!isEditMode && hrefId && data.title}
          href={hrefId}
        >
          <h2>{data?.title}</h2>
        </ConditionalLink>
      )}
      {data.description && <h3>{data?.description} </h3>}
      {buttonLink && (
        <Button
          as="a"
          className={cx('ueu', 'banner-down-button')}
          size="large"
          href={buttonLink['@id']}
        >
          {data.buttonText}
        </Button>
      )}
    </Segment>
  );
};

export default AldizkariView;
