import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
// import { toast } from 'react-toastify';
import { Grid, Image as SImage } from 'semantic-ui-react';

import DefaultImageSVG from '@plone/volto/components/manage/Blocks/Listing/default-image.svg';
import config from '@plone/volto/registry';

import { BuyEditionsForm } from 'volto-ueu/components';
// import { useUeuSaskia } from 'volto-ueu/components/saskia';
import { getRegistry } from 'volto-ueu/actions';
import Image from '@plone/volto/components/theme/Image/Image';

const AldizkariaCard = ({ content }) => {
  // const { addSaskira } = useUeuSaskia();
  const dispatch = useDispatch();

  // const [checkedPaper, setCheckedPaper] = useState(false);
  // const handleSubmit = (event, book_uuid, paper_uuid) => {
  //   if (event.target.paper.checked) {
  //     addSaskira(book_uuid, paper_uuid);
  //     setCheckedPaper(false);
  //   }
  //   toast.success(
  //     <Toast
  //       autoClose={3000}
  //       title={'Saskira gehituta!'}
  //       content="Aukeratu duzun edizioa saskira gehitu da! Saskia ikusteko, goiburuan dagoen orgatxoan klik egin dezakezu."
  //     />,
  //   );
  // };
  // const registryRecords = useSelector((state) => state.registry);
  const bazkide_deskontua_key = config.settings.registry.bazkide_deskontua;
  // const bazkide_deskontua = registryRecords?.records
  //   ? registryRecords?.records[bazkide_deskontua_key]
  //   : 0;
  useEffect(() => {
    dispatch(getRegistry(bazkide_deskontua_key));
  }, [dispatch, bazkide_deskontua_key]);
  const paper_editions =
    content.items?.length > 0 &&
    content.items.filter(
      (item) => item['@type'] === 'Edition' && item.format === 'paper',
    );
  return (
    <Grid className="aldizkaria-card">
      <Grid.Row>
        <Grid.Column>
          {!content?.image && (
            <SImage
              src={DefaultImageSVG}
              alt="This content has no image, this is a default placeholder."
              size="large"
            />
          )}
          {content?.image && (
            <Image className="ui image" item={content} alt={content.title} />
          )}
        </Grid.Column>
      </Grid.Row>
      {paper_editions.length > 0 && paper_editions[0].price > -1 ? (
        <Grid.Row>
          <Grid.Column>
            <BuyEditionsForm
              content={content}
              paper_edition={paper_editions[0]}
            />
          </Grid.Column>
        </Grid.Row>
      ) : (
        <></>
      )}
    </Grid>
  );
};

export default AldizkariaCard;
