import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, List, Image as SImage } from 'semantic-ui-react';

import ConditionalLink from '@plone/volto/components/manage/ConditionalLink/ConditionalLink';
import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';

import { getMyCourses } from 'volto-ueu/actions';
import Image from '@plone/volto/components/theme/Image/Image';

import { getRandomSrc } from 'volto-ueu/components/RandomImage/RandomImage';
// import noImage from 'volto-ueu/components/Variations/ListingBlock/Cards/ikastaroa-noimage.jpg';
import { IKASTAROA_FORM_ROUTE } from 'volto-ueu/constants';
import { isEntryInTime, enrolled } from './utils';

import moment from 'moment';
import { formatLL } from '../../utils';

const CourseInfoCard = ({ noButton = false, content }) => {
  const dispatch = useDispatch();
  let lang = useSelector((state) => state.intl.locale);
  const token = useSelector((state) => state.userSession.token);
  const myCourses = useSelector((state) => state.myCourses);
  moment.locale(lang);
  useEffect(() => {
    if (token && !myCourses.error && !myCourses.loaded && !myCourses.loading) {
      dispatch(getMyCourses());
    }
  }, [dispatch, myCourses, token]);
  return (
    <Grid className="CourseInfo">
      <Grid.Row>
        <Grid.Column width={8} className="CourseInfoData">
          <List bulleted>
            {content?.start_date && content?.end_date && (
              <List.Item>
                {content?.start_date === content?.end_date && (
                  <>
                    <strong>
                      <FormattedMessage id="Data:" defaultMessage="Data:" />
                    </strong>
                    {` ${formatLL(content?.start_date)} (${
                      content?.week_days
                    })`}
                  </>
                )}
                {content?.start_date !== content?.end_date && (
                  <>
                    <strong>
                      <FormattedMessage
                        id="Hasiera - Bukaera data:"
                        defaultMessage="Hasiera - Bukaera data:"
                      />
                    </strong>
                    {` ${formatLL(content?.start_date)} - ${formatLL(
                      content?.end_date,
                    )} (${content?.week_days})`}
                  </>
                )}
              </List.Item>
            )}
            {content?.course_location && (
              <List.Item>
                <strong>
                  <FormattedMessage id="Tokia:" defaultMessage="Tokia:" />
                </strong>{' '}
                <ConditionalLink
                  condition={content?.course_location_how_to_reach !== ''}
                  href={content?.course_location_how_to_reach}
                  title={content?.course_location_how_to_reach}
                >
                  {content?.course_location}
                </ConditionalLink>
              </List.Item>
            )}
            {/* {content?.course_location_how_to_reach && (
              <List.Item>
                <strong>Nola Heldu:</strong>{' '}
                {content?.course_location_how_to_reach && (
                  <UniversalLink
                    href={content?.course_location_how_to_reach}
                    title={content?.course_location_how_to_reach}
                  >
                    {content?.course_location_how_to_reach}
                  </UniversalLink>
                )}
              </List.Item>
            )} */}
            {content?.week_days &&
              content?.hour &&
              content?.week_days !== 'Online' &&
              content?.hour !== 'Online' && (
                <List.Item>
                  <strong>
                    <FormattedMessage
                      id="Ordutegia:"
                      defaultMessage="Ordutegia:"
                    />
                  </strong>
                  {` ${content?.hour}`}
                </List.Item>
              )}
            {content?.character && (
              <List.Item>
                <strong>
                  <FormattedMessage
                    id="Modalitatea:"
                    defaultMessage="Modalitatea:"
                  />
                </strong>{' '}
                {content?.character}
              </List.Item>
            )}
            {content?.number_of_hours > 0 && (
              <List.Item>
                <strong>
                  <FormattedMessage
                    id="Ordu kopurua:"
                    defaultMessage="Ordu kopurua:"
                  />
                </strong>{' '}
                {content?.number_of_hours}
              </List.Item>
            )}
            {content?.entry_limit && !content?.force_enroll_open && (
              <List.Item>
                <strong>
                  <FormattedMessage
                    id="Matrikula-epearen hasiera:"
                    defaultMessage="Matrikula-epearen hasiera:"
                  />
                </strong>{' '}
                {formatLL(content?.entry_limit)}
              </List.Item>
            )}
            {content?.entry_limit_end && !content?.force_enroll_open && (
              <List.Item>
                <strong>
                  {' '}
                  <FormattedMessage
                    id="Matrikula-epearen bukaera:"
                    defaultMessage="Matrikula-epearen bukaera:"
                  />
                </strong>{' '}
                {formatLL(content?.entry_limit_end)}
              </List.Item>
            )}
          </List>{' '}
          {myCourses.loading && !myCourses.loaded ? (
            <></>
          ) : (
            !noButton &&
            isEntryInTime(content) &&
            !enrolled(content.pk, myCourses?.courses) && (
              <UniversalLink
                className="ui primary button ueu"
                href={`${content['@id']}${IKASTAROA_FORM_ROUTE}`}
              >
                <FormattedMessage
                  id="Matrikulatu"
                  defaultMessage="Matrikulatu"
                />
              </UniversalLink>
            )
          )}
          {/* {enrolled(content.pk, myCourses?.courses) && (
            <Segment basic padded="very">
              <p>
                Ikastaro honetan matrikulatuta zaude. Zure matrikularen egoera{' '}
                <strong>
                  "{enrollStatus(content.pk, myCourses?.courses)}"
                </strong>{' '}
                da
              </p>
              {!noButton &&
                isEntryInTime(content) &&
                !enrollStatus(content.pk, myCourses?.courses) ===
                  'Onartuta' && (
                  <UniversalLink
                    className="ui primary button ueu"
                    href={`${content['@id']}${IKASTAROA_PAY_ROUTE}`}
                  >
                    <FormattedMessage
                      id="Ordaindu matrikula"
                      defaultMessage="Ordaindu matrikula"
                    />
                  </UniversalLink>
                )}
            </Segment>
          )} */}
        </Grid.Column>
        <Grid.Column width={4} className="CourseInfoImage">
          {!content?.image && (
            <SImage
              src={getRandomSrc(content.UID, 'courses')}
              alt="Ikastaro honek ez du irudirik, hau defektuzko irudi bat da."
            />
          )}
          {content?.image && (
            <Image className="ui image" item={content} alt={content.title} />
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default CourseInfoCard;
