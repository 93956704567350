import { isInternalURL } from '@plone/volto/helpers/Url/Url';
import { flattenToAppURL } from '@plone/volto/helpers/Url/Url';

export function getTeaserImageURL(href, image) {
  let image_url = '';
  if (image) {
    if (isInternalURL(image['@id'])) {
      image_url = image?.image_scales?.image?.[0]?.scales?.great?.download
        ? `${image['@id']}/${image?.image_scales?.image?.[0]?.scales?.great?.download}`
        : `${image['@id']}/@@images/image/great`;
    } else {
      image_url = image['@id'];
    }
  } else {
    image_url = `${href['@id']}/@@images/${href.image_field || 'preview_image'}`;
  }
  return flattenToAppURL(image_url);
}
