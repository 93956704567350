import React from 'react';
import { Grid, Image as SImage, Header } from 'semantic-ui-react';
import Image from '@plone/volto/components/theme/Image/Image';
import DefaultImageSVG from '@plone/volto/components/manage/Blocks/Listing/default-image.svg';
import ConditionalLink from '@plone/volto/components/manage/ConditionalLink/ConditionalLink';

const IritziaCard = ({ item, isEditMode }) => {
  return (
    <Grid divided textAlign="center" className="iritzia-card">
      <Grid.Column width={16}>
        <Grid.Row>
          <ConditionalLink item={item} condition={!isEditMode}>
            {!item.image_field && (
              <SImage
                circular
                src={DefaultImageSVG}
                alt="This content has no image, this is a default placeholder."
                size="small"
              />
            )}
            {item?.image_field && (
              <Image
                className="ui image circular"
                item={item}
                alt={item.title}
              />
            )}
          </ConditionalLink>
        </Grid.Row>
        <Grid.Row>
          <Header size="small" dividing>
            <ConditionalLink to={item?.author_link} condition={!isEditMode}>
              {item?.author_name}
            </ConditionalLink>
          </Header>
        </Grid.Row>
        <Grid.Row>
          <h3>
            <ConditionalLink item={item} condition={!isEditMode}>
              {item.title}
            </ConditionalLink>
          </h3>
        </Grid.Row>
      </Grid.Column>
    </Grid>
  );
};

export default IritziaCard;
