import React from 'react';
import { Image as SImage, Grid } from 'semantic-ui-react';
import DefaultImageSVG from '@plone/volto/components/manage/Blocks/Listing/default-image.svg';
import ConditionalLink from '@plone/volto/components/manage/ConditionalLink/ConditionalLink';
import Image from '@plone/volto/components/theme/Image/Image';
import { useSelector } from 'react-redux';
import moment from 'moment';

const EventCard = ({
  item,
  isEditMode,
  showDescription,
  destakatua,
  showDate,
}) => {
  let lang = useSelector((state) => state.intl.locale);
  moment.locale(lang);
  return (
    <Grid className={`event-card type-${item['@type']}`}>
      <Grid.Column width={12}>
        <Grid.Row>
          <ConditionalLink item={item} condition={!isEditMode}>
            {!item.image_field && (
              <SImage
                src={DefaultImageSVG}
                alt="This content has no image, this is a default placeholder."
                size="small"
              />
            )}
            {item.image_field && (
              <Image className="ui image" item={item} alt={item.title} />
            )}
          </ConditionalLink>
        </Grid.Row>
        <Grid.Row>
          {showDate && item?.start !== null && (
            <div className="event-date">
              <span className="event-month">
                {moment(item?.start).format('MMMM')}
              </span>
              <span className="event-day">
                {moment(item?.start).format('DD')}
              </span>
              /
              {moment(item?.start).hour() > 0 && (
                <span className="event-time">
                  {moment(item?.start).format('HH:mm')}
                </span>
              )}
            </div>
          )}
          {showDate && item?.start_date !== null && (
            <div className="event-date">
              <span className="event-month">
                {moment(item?.start_date).format('MMMM')}
              </span>
              <span className="event-day">
                {moment(item?.start_date).format('DD')}
              </span>
              /
              {moment(item?.start_date).hour() > 0 && (
                <span className="event-time">
                  {moment(item?.start_date).format('HH:mm')}
                </span>
              )}
            </div>
          )}
          <h3>
            <ConditionalLink item={item} condition={!isEditMode}>
              {item.title}
            </ConditionalLink>
          </h3>
        </Grid.Row>
        {showDescription && item.description && (
          <Grid.Row>
            <p>{item.description}</p>
          </Grid.Row>
        )}
        <Grid.Row className="event-location">
          {item.location && <p>{item.location}</p>}
          {item.course_location && <p>{item.course_location}</p>}
        </Grid.Row>
      </Grid.Column>
    </Grid>
  );
};

export default EventCard;
