/**
 * EventView view component.
 * @module components/theme/View/EventView
 */
import React from 'react';
import { useSelector } from 'react-redux';
import {
  Grid,
  Container,
  // Popup,
  Segment,
} from 'semantic-ui-react';
import Image from '@plone/volto/components/theme/Image/Image';

import StringToHTML from 'volto-ueu/components/Utils/StringToHTML';

import moment from 'moment';
import PropTypes from 'prop-types';

/**
 * EventView view component class.
 * @function EventView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const EventView = ({ content, location }) => {
  let lang = useSelector((state) => state.intl.locale);
  moment.locale(lang);
  return (
    <Container className="view-wrapper">
      <Grid stackable>
        <Grid.Column width={5}>
          {content.title && <h1 className="">{content.title}</h1>}
          <p className="documentDescription">{content.description}</p>
          {content.text && <StringToHTML string={content.text.data} />}
          <Grid className="event-data">
            <Grid.Column width={4}>
              <Segment className="event-date">
                <span className="event-month">
                  {moment(content?.start).format('MMMM')}
                </span>
                <span className="event-day">
                  {moment(content?.start).format('DD')}
                </span>
                <span className="event-time">
                  {moment(content?.start).format('HH:mm')}
                </span>
              </Segment>
            </Grid.Column>
            <Grid.Column width={8}>
              <Segment basic className="event-location">
                {content.location}
              </Segment>
            </Grid.Column>
          </Grid>
        </Grid.Column>
        <Grid.Column width={7}>
          {content.preview_image && (
            <figure>
              <Image
                className="ui image"
                imageField="preview_image"
                item={content}
                alt={content.title}
              />
              {content.image_caption && (
                <figcaption>{content.image_caption}</figcaption>
              )}
            </figure>
          )}
        </Grid.Column>
      </Grid>
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
EventView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    text: PropTypes.shape({
      data: PropTypes.string,
    }),
  }).isRequired,
};

export default EventView;
