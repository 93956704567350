import React from 'react';
import { useSelector } from 'react-redux';
import { Image as SImage, Grid } from 'semantic-ui-react';
import ConditionalLink from '@plone/volto/components/manage/ConditionalLink/ConditionalLink';
import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';
import Icon from '@plone/volto/components/theme/Icon/Icon';
import DefaultImageSVG from '@plone/volto/components/manage/Blocks/Listing/default-image.svg';
import addSVG from '@plone/volto/icons/add.svg';
import mini_txiotesia from 'volto-ueu/theme/images/mini_txiotesia.jpeg';
import Image from '@plone/volto/components/theme/Image/Image';

import moment from 'moment';

const TxiotesiaCard = ({ item, isEditMode, featureWinner }) => {
  let lang = useSelector((state) => state.intl.locale);
  moment.locale(lang);
  return (
    <Grid className="txiotesia-card">
      {featureWinner && (
        <>
          <Grid.Row>
            <Grid.Column width={12} verticalAlign="middle">
              <img alt="Txiotesiko logotipoa" src={mini_txiotesia} />
              <h3 className="award">{item.award}</h3>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="winner-info">
            <Grid.Column>
              <UniversalLink className="image" item={item}>
                {item?.image_field && (
                  <Image
                    className="ui image circular"
                    item={item}
                    alt={item.title}
                  />
                )}
              </UniversalLink>
              <h4>{item?.egilea}</h4>
              <span>{`@${item?.screenname}`}</span>
              <h3 className="txiotesia-card-title">
                <ConditionalLink item={item} condition={!isEditMode}>
                  {item.title}
                </ConditionalLink>
              </h3>
            </Grid.Column>
          </Grid.Row>
        </>
      )}
      {!featureWinner && (
        <>
          <Grid.Row>
            <Grid.Column>
              <UniversalLink className="small-image" item={item}>
                {!item.image_field && (
                  <SImage
                    circular
                    src={DefaultImageSVG}
                    alt="This content has no image, this is a default placeholder."
                    size="small"
                  />
                )}
                {item?.image_field && (
                  <Image
                    className="ui image circular"
                    item={item}
                    alt={item.title}
                  />
                )}
              </UniversalLink>
              <h3 className="txiotesia-card-title">
                <ConditionalLink item={item} condition={!isEditMode}>
                  {item.title}
                </ConditionalLink>
              </h3>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <strong>Egilea:</strong> {` ${item?.egilea}`}{' '}
              <a
                href={
                  item?.mastodon !== true
                    ? `https://twitter.com/${item?.screenname}`
                    : `https://mastodon.eus/@${item?.screenname}`
                }
              >
                {`@${item?.screenname}`}
              </a>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <strong>Etiketak: </strong>{' '}
              {item?.Subject?.map((subject, i) => (
                <React.Fragment key={i}>
                  {i > 0 && ', '}
                  <a
                    key={i}
                    href={`/txiotesia/bilaketa?query=%5B%7B%22i%22%3A%22Subject%22%2C%22o%22%3A%22paqo.list.contains%22%2C%22v%22%3A%5B%22${subject}%22%5D%7D%5D`}
                  >
                    {subject}
                  </a>
                </React.Fragment>
              ))}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column textAlign={'right'}>
              <ConditionalLink item={item} condition={!isEditMode}>
                <Icon name={addSVG} />
              </ConditionalLink>
            </Grid.Column>
          </Grid.Row>
        </>
      )}
    </Grid>
  );
};

export default TxiotesiaCard;
